<template>
  <div style="margin-top: 20px;">
    <!-- 巡检任务 -->
    <div class="box-card" style="text-align: left;">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef"
        @submit.native.prevent>
        <el-form-item label="巡检计划：">
          <el-input maxlength="30" v-model="queryForm.plan_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.plan_name = validSpace(e))" placeholder="请输入巡检计划"></el-input>
        </el-form-item>
        <el-form-item label="日期：">
          <el-date-picker v-model="queryForm.select_date" value-format="yyyy-MM-dd" type="date" clearable
            placeholder="请选择日期" @change="onSubmit()">
          </el-date-picker>
          <!-- <el-input maxlength="30" v-model="queryForm.date" @keyup.enter.native="onSubmit()" @input="(e) => (queryForm.date = validSpace(e))" placeholder="请输入巡检任务"></el-input> -->
        </el-form-item>
        <el-form-item label="巡检人员：">
          <el-input maxlength="30" v-model="queryForm.inspector_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.inspector_name = validSpace(e))" placeholder="请输入巡检人员"></el-input>
        </el-form-item>
        <!-- <el-form-item label="状态：">
            <el-select popper-class="my-select" clearable v-model="queryForm.task_status" placeholder="请选择任务状态" @clear="queryForm.task_status = null" @change="queryForm.task_status = queryForm.task_status? queryForm.task_status: null;onSubmit()">
              <el-option v-for="item in getOptions('AssetTaskStatus', false)" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="巡检任务">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('17d252286e0f46768f801de2')">删除
            </lbButton>
            <lbButton type="setting" icon="xinzeng" @click="openAppointDialog"
              v-if="this.getUserInfo.user_name != 'admin' && isShowBtn('3cc72326584e4d008c323556')">指派</lbButton>
          </div>
        </template>
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="start_time">
              {{ $moment(row.start_time).format("YYYY-MM-DD") }}
            </template>
            <template slot-scope="{row}" slot="during_time">
              {{ $moment(row.start_time).format("HH:mm:ss") }} ~ {{ $moment(row.end_time).format("HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="dealCount">
              {{ row.dealCount ? row.dealCount : 0 }} / {{ row.inspectionCount ? row.inspectionCount : 0 }}
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookInspectionTask(row.Id)">查看</lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column label="日期">
            <template slot-scope="scope">
              {{ $moment(scope.row.start_time).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
          <el-table-column label="巡检时段" width="150">
            <template slot-scope="scope">
              {{ $moment(scope.row.start_time).format("HH:mm:ss") }} ~ {{ $moment(scope.row.end_time).format("HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="route_name" label="巡检路线"></el-table-column>
          <el-table-column prop="plan_name" label="巡检计划"></el-table-column>
          <el-table-column prop="project_name" label="资产项目"></el-table-column>
          <el-table-column prop="inspector_name" label="巡检人员"></el-table-column>
          <el-table-column label="任务进度">
            <template slot-scope="scope">
              {{scope.row.dealCount ? scope.row.dealCount: 0 }} / {{scope.row.inspectionCount ? scope.row.inspectionCount: 0}}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="200">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookInspectionTask(scope.row.Id)">查看</lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog title="指派巡检人员" :isShow="isAppointDialog" width="35%" @close="isAppointDialog = false" footerSlot>
      <el-form :model="appointForm" :rules="appointFormRules" ref="appointFormRef" label-width="140px"
        class="demo-ruleForm">
        <el-form-item label="当前巡检人员：">{{ appointForm.name }} </el-form-item>
        <el-form-item label="修改巡检人员：" prop="inspector_id">
          <el-select popper-class="my-select" clearable v-model="appointForm.inspector_id" placeholder="请选择巡检人员"
            @change="inspectorChange" :popper-append-to-body="false">
            <el-option v-for="item in inspectorSelectList" :key="item.Id" :label="item.user_name"
              :value="item.Id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" maxlength="100" v-model.trim="appointForm.remark" :rows="5" placeholder="请输入备注"
            @blur="appointForm.remark = ((appointForm.remark.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAppointDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="onSubmitAppoint()">确认指派</lbButton>
      </span>
    </lebo-dialog>
    <!-- 查看巡检任务 -->
    <lebo-dialog title='查看巡检任务' width="60%" :isShow="showLookInspectionTask" @close="showLookInspectionTask = false"
      footerSlot closeOnClickModal>
      <lookInspectionTask v-if="showLookInspectionTask" :id="dialogLookInspectionTaskId"
        @closeDialog="closeInspectionTask"></lookInspectionTask>
    </lebo-dialog>
  </div>
</template>

<script>
import { getInspectionTaskList, getInspectorSelectList, getInspectionTaskDetail, setAppointInspectionTask, delInspectionTask } from '@/api/assetsManagement'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import lookInspectionTask from './components/lookInspectionTask/index.vue'
export default {
  components: { lookInspectionTask },
  data () {
    return {
      queryForm: {
        plan_name: '',
        select_date: null,
        inspector_name: '',
        // 任务状态 1 待执行 2 执行中 3 已完成 4 已终止 5 已过期
        task_status: null,
        PageSize: 10,
        PageIndex: 1
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
      // 是否展示指派任务对话框
      isAppointDialog: false,
      // 巡检人员下拉列
      inspectorSelectList: [],
      // 指派表单
      appointForm: {
        Id: '',
        name: '',
        inspector_id: '',
        inspector_name: '',
        remark: ''
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '日期',
            prop: 'start_time',
            slot: true
          }, {
            label: '巡检时段',
            prop: 'during_time',
            slot: true
          }, {
            label: '巡检路线',
            prop: 'route_name'
          }, {
            label: '巡检计划',
            prop: 'plan_name'
          }, {
            label: '资产项目',
            prop: 'project_name'
          }, {
            label: '巡检人员',
            prop: 'inspector_name'
          }, {
            label: '任务进度',
            prop: 'dealCount',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      appointFormRules: {
        inspector_id: [
          { required: true, message: '请选择巡检人员', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ]
      },
      dialogLookInspectionTaskId: '',
      showLookInspectionTask: false
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetInspectionTaskList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getUserInfo'])
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取巡检任务列表
    async fnGetInspectionTaskList () {
      const res = await getInspectionTaskList(this.queryForm)
      console.log(res)
      if (res && res.Code === 200 && res.Data) {
        this.tableData = res.Data.DataList.concat()
        this.page.total = res.Data.TotalCount
        this.total = res.Data.TotalCount
      } else {
        this.tableData = []
        this.page.total = 0
        this.total = 0
      }
    },
    setTaskStatus (status, endTime, startTime) {
      if (status === 1) {
        if (this.$moment().diff(this.$moment(endTime)) > 0) {
          return 5
        } else {
          if (this.$moment().diff(this.$moment(startTime)) > 0) {
            return 2
          } else {
            return 1
          }
        }
      } else if (status === 3) {
        return status
      } else if (status === 4) {
        return status
      }
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetInspectionTaskList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetInspectionTaskList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetInspectionTaskList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetInspectionTaskList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetInspectionTaskList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 点击删除按钮
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的巡检任务，您确定要删除选中的巡检任务吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i].Id)
            if (arr.length === this.multipleSelection.length) {
              obj.Id = arr.join(',')
              this.fndelInspectionTask(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的巡检任务！')
      }
    },
    // 删除巡检任务请求
    async fndelInspectionTask (obj) {
      const res = await delInspectionTask(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetInspectionTaskList()
    },
    // 展示指派巡检人员对话框
    openAppointDialog () {
      var that = this
      if (this.multipleSelection.length > 0) {
        console.log(this.multipleSelection)
        var arr = this.multipleSelection.filter((item) => {
          return item.task_status === 1 || item.task_status === 2
        })
        if (arr.length < this.multipleSelection.length) {
          this.$msg.warning('请选择待执行或执行中的巡检任务！')
        } else {
          this.fnGetInspectorSelectList()
          this.isAppointDialog = true
          var nameArr = []
          var idArr = []
          this.multipleSelection.forEach((item) => {
            nameArr.push(item.inspector_name)
            idArr.push(item.Id)
          })
          this.$nextTick(() => {
            this.appointForm.Id = idArr.join(',')
            this.appointForm.name = [...new Set(nameArr)].join('、')
          })
        }
      } else {
        this.$msg.warning('请选择要指派的巡检任务！')
      }
    },
    // 获取巡检人员下拉列
    async fnGetInspectorSelectList () {
      const res = await getInspectorSelectList({
        person_type: 1 // 资产人员类型 巡检人员 = 1, 维修人员 = 2
      })
      // console.log('获取巡检人员下拉列', res);
      if (res && res.Code === 200) {
        this.inspectorSelectList = res.Data
      }
    },
    // 巡检人员下拉列发生改变时触发
    inspectorChange (val) {
      console.log(val)
      var arr = this.inspectorSelectList.filter((item) => {
        return item.Id === val
      })
      this.appointForm.inspector_name = arr[0].user_name
    },
    // 确认指派
    onSubmitAppoint () {
      // 对整个表单进行校验
      this.$refs.appointFormRef.validate((valid) => {
        // 成功
        if (valid) {
          this.fnSetAppointInspectionTask({
            Id: this.appointForm.Id,
            inspector_id: this.appointForm.inspector_id,
            inspector_name: this.appointForm.inspector_name,
            remark: this.appointForm.remark
          })
        } else {
          return false
        }
      })
    },
    // 指派请求
    async fnSetAppointInspectionTask (obj) {
      const res = await setAppointInspectionTask(obj)
      // console.log(res);
      this.isAppointDialog = false
      this.fnGetInspectionTaskList()
    },
    // 查看巡检任务
    lookInspectionTask (id) {
      this.dialogLookInspectionTaskId = id
      this.showLookInspectionTask = true
    },
    closeInspectionTask () {
      this.showLookInspectionTask = false
      this.fnGetInspectionTaskList()
    }
  }

}
</script>

<style scoped lang="less">/*下拉框最后一个显示不完全*/
/deep/.el-select-dropdown__wrap.el-scrollbar__wrap {
  margin-bottom: 0 !important;
}</style>

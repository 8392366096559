var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"box-card",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"title"},[_vm._v("基本信息")]),_c('el-divider'),_c('el-form',{staticClass:"detailForm",attrs:{"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"计划名称："}},[_vm._v(_vm._s(_vm.detailForm.plan_name))]),_c('el-form-item',{attrs:{"label":"资产项目："}},[_vm._v(_vm._s(_vm.detailForm.project_name))]),_c('el-form-item',{attrs:{"label":"巡检路线："}},[_vm._v(_vm._s(_vm.detailForm.route_name))]),_c('el-form-item',{attrs:{"label":"巡检时段："}},[_vm._v(" "+_vm._s(_vm.$moment(_vm.detailForm.start_time).format("YYYY-MM-DD HH:mm:ss"))+" ~ "+_vm._s(_vm.$moment(_vm.detailForm.end_time).format("HH:mm:ss"))+" ")])],1),_c('div',{staticClass:"title"},[_vm._v("任务进度")]),_c('el-divider'),_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%","margin-top":"15px"},attrs:{"data":_vm.detailForm.list_inspection,"tooltip-effect":"dark","height":"200","border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"inspector_name","label":"巡检人员"}}),_c('el-table-column',{attrs:{"prop":"inspection_name","label":"巡检项"}}),_c('el-table-column',{attrs:{"prop":"real_time","label":"实际巡检时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.deal_status != 7 ? "/" : _vm.$moment(scope.row.real_time).format("YYYY-MM-DD HH:mm:ss"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"执行状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:('color:' + _vm.dealStatusColor(scope.row.deal_status))},[_vm._v(" "+_vm._s(_vm.getWayName("AssetDealStatus", scope.row.deal_status))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"巡检结果"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:(scope.row.deal_status != 7
                            ? 'color:#606266'
                            : scope.row.deal_result == 1
                                ? 'color:#67C23A;'
                                : 'color:#ee0000;')},[_vm._v(" "+_vm._s(scope.row.deal_status != 7 ? "/" : scope.row.deal_result == 1 ? "合格" : "不合格")+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"详情"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('lbButton',{attrs:{"type":"succes","icon":"chakan","hint":"查看","disabled":scope.row.deal_status != 7 ? true : false},on:{"click":function($event){return _vm.openDetailDialog(scope.row)}}})]}}])})],1),_c('div',{staticClass:"title"},[_vm._v("指派记录")]),_c('el-divider'),_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%","margin-top":"15px"},attrs:{"data":_vm.detailForm.list_appoint,"tooltip-effect":"dark","height":"150","border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"inspector_name","label":"巡检人员"}}),_c('el-table-column',{attrs:{"label":"指派时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"create_name","label":"平台账号"}}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注"}})],1)],1),_c('lebo-dialog',{attrs:{"title":"详情","isShow":_vm.isDetailDialog,"width":"40%","footerSlot":""},on:{"close":function($event){_vm.isDetailDialog = false}}},[_c('el-form',{staticClass:"detailForm",attrs:{"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"巡检结果："}},[_c('span',{staticClass:"plan_status_box",style:(_vm.infoForm.deal_result == 1
                        ? 'background-color:#67C23A;'
                        : 'background-color:#ee0000;')},[_vm._v(" "+_vm._s(_vm.infoForm.deal_result == 1 ? "合格" : "不合格")+" ")])]),(_vm.infoForm.deal_result != 1)?_c('el-form-item',{attrs:{"label":"异常说明："}},[_vm._v(_vm._s(_vm.infoForm.exception_note))]):_vm._e(),_c('el-form-item',{attrs:{"label-width":"0"}},[_c('div',{staticClass:"image_box",style:(_vm.infoForm.pic.length > 1
                            ? 'justify-content: space-between;'
                            : 'justify-content: center;')},_vm._l((_vm.infoForm.pic),function(item,index){return _c('el-image',{key:index,staticClass:"elImage",style:(_vm.infoForm.pic.length === 3 ? 'width: 30%;' : 'width: 45%;'),attrs:{"src":item,"preview-src-list":_vm.infoForm.pic,"z-index":9999}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"iconfont lebo-picerr"}),_c('span',[_vm._v("加载失败")])])])}),1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <!-- 查看巡检任务 -->
    <div>
        <div class="box-card" style="text-align: left">
            <div class="title">基本信息</div>
            <el-divider></el-divider>
            <el-form label-width="130px" class="detailForm">
                <el-form-item label="计划名称：">{{
                    detailForm.plan_name
                }}</el-form-item>
                <el-form-item label="资产项目：">{{
                    detailForm.project_name
                }}</el-form-item>
                <el-form-item label="巡检路线：">{{
                    detailForm.route_name
                }}</el-form-item>
                <!-- <el-form-item label="起止日期：">{{
               $moment(detailForm.plan_start_time).format('YYYY-MM-DD')+' 至 '+
           $moment(detailForm.plan_end_time).format('YYYY-MM-DD') }}</el-form-item>
          <el-form-item label="巡检周期：">{{getWorkType(detailForm.work_type)}}
            <span v-if="detailForm.work_type != 1">（{{detailForm.workSet}}）</span>
          </el-form-item> -->
                <el-form-item label="巡检时段：">
                    {{ $moment(detailForm.start_time).format("YYYY-MM-DD HH:mm:ss") }} ~
                    {{ $moment(detailForm.end_time).format("HH:mm:ss") }}
                </el-form-item>
                <!-- <el-form-item label="状态：">
            <span class="plan_status_box" :style=" 'background-color:' + taskStatusColor(setTaskStatus(detailForm.task_status, detailForm.end_time, detailForm.start_time))">
              {{getWayName("AssetTaskStatus", setTaskStatus(detailForm.task_status, detailForm.end_time, detailForm.start_time))}}
            </span>
          </el-form-item> -->
            </el-form>
            <div class="title">任务进度</div>
            <el-divider></el-divider>
            <!-- 表格 -->
            <el-table ref="multipleTable" :data="detailForm.list_inspection" tooltip-effect="dark"
                style="width: 100%; margin-top: 15px" height="200" border stripe>
                <!-- <el-table-column prop="start_time" label="巡检时段"></el-table-column> -->
                <el-table-column prop="inspector_name" label="巡检人员"></el-table-column>
                <el-table-column prop="inspection_name" label="巡检项"></el-table-column>
                <el-table-column prop="real_time" label="实际巡检时间">
                    <template slot-scope="scope">
                        {{
                            scope.row.deal_status != 7
                            ? "/"
                            : $moment(scope.row.real_time).format("YYYY-MM-DD HH:mm:ss")
                        }}
                    </template>
                </el-table-column>
                <!-- deal_status(巡检状态) 1：待巡检 4:已终止 5：已过期 6：执行中 7：已完成 -->
                <el-table-column label="执行状态">
                    <template slot-scope="scope">
                        <span :style="'color:' + dealStatusColor(scope.row.deal_status)">
                            <!-- {{ dealStatus( scope.row.deal_status ) }} -->
                            {{ getWayName("AssetDealStatus", scope.row.deal_status) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="巡检结果">
                    <!-- 1：合格 2：不合格 -->
                    <template slot-scope="scope">
                        <span :style="scope.row.deal_status != 7
                                ? 'color:#606266'
                                : scope.row.deal_result == 1
                                    ? 'color:#67C23A;'
                                    : 'color:#ee0000;'
                            ">
                            {{
                                scope.row.deal_status != 7
                                ? "/"
                                : scope.row.deal_result == 1
                                    ? "合格"
                                    : "不合格"
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="详情">
                    <template slot-scope="scope">
                        <lbButton type="succes" icon="chakan" hint="查看"
                            :disabled="scope.row.deal_status != 7 ? true : false" @click="openDetailDialog(scope.row)">
                        </lbButton>
                        <!-- <el-button type="success" size="mini" :disabled="scope.row.deal_status != 7 ? true:false" @click="openDetailDialog(scope.row)" icon="iconfont lebo-chakan">查看</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="title">指派记录</div>
            <el-divider></el-divider>
            <!-- 表格 -->
            <el-table ref="multipleTable" :data="detailForm.list_appoint" tooltip-effect="dark"
                style="width: 100%; margin-top: 15px" height="150" border stripe>
                <el-table-column prop="inspector_name" label="巡检人员"></el-table-column>
                <el-table-column label="指派时间">
                    <template slot-scope="scope">
                        {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
                    </template>
                </el-table-column>
                <el-table-column prop="create_name" label="平台账号"></el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
            </el-table>
        </div>
        <lebo-dialog title="详情" :isShow="isDetailDialog" width="40%" @close="isDetailDialog = false" footerSlot>
            <el-form label-width="130px" class="detailForm">
                <el-form-item label="巡检结果：">
                    <span class="plan_status_box" :style="infoForm.deal_result == 1
                            ? 'background-color:#67C23A;'
                            : 'background-color:#ee0000;'
                        ">
                        {{ infoForm.deal_result == 1 ? "合格" : "不合格" }}
                    </span>
                </el-form-item>
                <el-form-item label="异常说明：" v-if="infoForm.deal_result != 1">{{
                    infoForm.exception_note
                }}</el-form-item>
                <el-form-item label-width="0">
                    <div class="image_box" :style="infoForm.pic.length > 1
                                ? 'justify-content: space-between;'
                                : 'justify-content: center;'
                            ">
                        <el-image class="elImage" :style="infoForm.pic.length === 3 ? 'width: 30%;' : 'width: 45%;'"
                            v-for="(item, index) in infoForm.pic" :key="index" :src="item" :preview-src-list="infoForm.pic"
                            :z-index="9999">
                            <div slot="error" class="image-slot">
                                <i class="iconfont lebo-picerr"></i>
                                <span>加载失败</span>
                            </div>
                        </el-image>
                    </div>
                </el-form-item>
            </el-form>
        </lebo-dialog>
    </div>
</template>

<script>
import { getInspectionTaskDetail } from '@/api/assetsManagement'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailForm: {},
      isDetailDialog: false,
      infoForm: {
        deal_result: null,
        exception_note: '',
        pic: []
      }
    }
  },
  created () {
    this.fnGetInspectionTaskDetail()
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    // 获取巡检任务详情
    async fnGetInspectionTaskDetail () {
      const res = await getInspectionTaskDetail({
        Id: this.id
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.detailForm = res.Data.task_detail
        this.detailForm.list_inspection = res.Data.list_inspection
        this.detailForm.list_appoint = res.Data.list_appoint
        this.detailForm.work_set = this.detailForm.work_set
          ? this.detailForm.work_set.split(',').filter((s) => {
            return s // 注：IE9(不包含IE9)以下的版本没有trim()方法
          })
          : []
        if (this.detailForm.work_set.length > 0) {
          var arr = []
          this.detailForm.work_set.forEach((item) => {
            if (this.detailForm.work_type === 2) {
              // 周
              arr.push(this.getWorkData(Number(item)))
            } else if (this.detailForm.work_type === 3) {
              // 自定义
              arr.push(item + '日')
            }
          })
          this.detailForm.workSet = arr.join('、')
          console.log(this.detailForm.workSet)
        }
        if (res.Data.task_detail.inspection_order === 2) {
          this.detailForm.list_inspection =
                        this.detailForm.list_inspection.sort(this.compare('sort_number'))
        }
        console.log('inspection_order------', this.detailForm.list_inspection)
      }
    },
    // 排序
    compare (property) {
      return function (a, b) {
        var value1 = a[property]
        var value2 = b[property]
        return value1 - value2
      }
    },
    getWorkType (type) {
      switch (type) {
        case 1:
          return '每天'
        case 2:
          return '周'
        case 3:
          return '自定义'
      }
    },
    setTaskStatus (status, endTime, startTime) {
      if (status === 1) {
        if (this.$moment().diff(this.$moment(endTime)) > 0) {
          return 5
        } else {
          if (this.$moment().diff(this.$moment(startTime)) > 0) {
            return 2
          } else {
            return 1
          }
        }
      } else if (status === 3) {
        return status
      } else if (status === 4) {
        return status
      }
    },
    getWorkData (type) {
      switch (type) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 7:
          return '周日'
      }
    },
    // 展开查看详情对话框
    openDetailDialog (row) {
      console.log(row)
      this.isDetailDialog = true
      var that = this
      this.$nextTick(() => {
        that.infoForm.pic = row.pic ? row.pic.split(',') : []
        that.infoForm.deal_result = row.deal_result
        that.infoForm.exception_note = row.exception_note
      })
    }
  }
}
</script>

<style scoped lang="less">
.title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
}

.detailForm {
    /deep/ .el-form-item {
        margin-bottom: 0;
    }
}

.plan_status_box {
    padding: 5px 10px;
    color: #fff;
}

.image_box {
    display: flex;
    align-items: center;

    .elImage {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        /deep/ .image-slot {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: #f5f7fa;
            color: #999;
            .iconfont{
              font-size: 36px;
            }
        }

        /deep/ .el-image__inner {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
}
</style>
